import React from 'react';
import Layout from '../components/Layout';
import pic5 from '../assets/images/pic05.jpg';
import n95 from '../assets/images/n95-respirator.png';
const Blog = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <div className="wrapper">
        <header>
          <div className="inner"></div>
        </header>
        <div className="inner">
          <h3 className="major">Recent Posts</h3>
          <div className="row gtr-uniform">
            <div className="col-6">
              <span className="image fit">
                <img src={n95} alt="n95 respirator to prevent spread of coronavirus" />
                <a href="/coronavirus">Moving during the Coronavirus</a>
              </span>
            </div>
            <div className="col-6">
              <span className="image fit">
                <img src={pic5} alt="" />
                <a href="/BlogPost">Blog title</a>
              </span>
            </div>
            <div className="col-6">
              <span className="image fit">
                <img src={pic5} alt="" />
                <a href="/BlogPost">Blog title</a>
              </span>
            </div>
            <div className="col-6">
              <span className="image fit">
                <img src={pic5} alt="" />
                <a href="/BlogPost">Blog title</a>
              </span>
            </div>
          </div>
          <div className="blog-space">
            <a href="#moving-quote" className="get-my-quote">
              Get my quote now
            </a>
          </div>
        </div>
      </div>
    </section>
  </Layout>
);
export default Blog;
